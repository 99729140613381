<template>
  <div class="home animate__animated animate__fadeIn pt-4">
<!--    <img
      :src="require('@/assets/banners/address-updates.png')"
      class="w-100 mb-4"
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>-->
    <!-- First Name -->
    <div class="mb-4">
      <h5 class="fs-la">Your first name</h5>
      <input
        type="text"
        placeholder="First Name"
        class="w-100 p-3 fs-md mb-2"
        :value="firstName"
        @input="(e) => selectedChange('firstName', e.target.value)"
      />
      <p class="mb-0 fs-md" v-if="errors.firstName" style="color: #c64a45">
        * This field is required
      </p>
    </div>

    <!-- First Name -->
    <div class="mb-4">
      <h5 class="fs-la">Your last name</h5>
      <input
        type="text"
        placeholder="Last Name"
        class="w-100 p-3 fs-md mb-2"
        :value="lastName"
        @input="(e) => selectedChange('lastName', e.target.value)"
      />
      <p class="mb-0 fs-md" v-if="errors.lastName" style="color: #c64a45">
        * This field is required
      </p>
    </div>

    <!-- Email -->
    <div class="medium-margin-bottom">
      <h5 class="fs-la">Your email</h5>
      <p style="margin-top: 3px;margin-bottom: 6px" class="fs-xs">We promise to only use this to email you about your move</p>
      <div class="d-flex">
        <input
          class="email-input mb-1 p-3 ps-4 w-100 fs-md"
          type="text"
          :class="errors.email ? 'error-input' : ''"
          placeholder="e.g Alexander@outlook.com"
          :value="email"
          @input="
            (e) => {
              updateValue('email', e.target.value);
              validateEmail();
            }
          "
          :disabled="referrer == 'TaylorRose' || referrer == 'AddressUpdater'"
        />
        <div
          v-if="email.length > 0 && regexEmail(email)"
          class="d-flex align-items-center"
          style="height: 58px"
        >
          <img class="ms-3" :src="require('@/assets/circle-check.png')" alt="circle-check" />
        </div>
      </div>
      <p v-if="errors.email" style="color: #c64a45">
        {{
          referrer == "TaylorRose"
            ? "The email address provided is not valid. Please contact your support team"
            : "Enter a valid email address"
        }}
      </p>
    </div>

    <!-- Confirm your Email -->
    <div v-if="displayConfirmEmail" class="medium-margin-bottom mt-4">
      <h5 class="fs-la">Confirm your email</h5>
      <div class="d-flex">
        <input
          class="email-input mb-1 p-3 ps-4 w-100 fs-md"
          type="text"
          placeholder="e.g Alexander@outlook.com"
          :value="confirmEmail"
          @input="(e) => updateValue('confirmEmail', e.target.value)"
        />
      </div>
      <p class="mb-2 fs-md" v-if="errors.existsUnderDifferentName" style="color: #c64a45">
        This email address is already linked to another customer. Please provide a unique email to continue 😊
      </p>
      <p v-if="errors.confirmEmail" style="color: #c64a45">
        The email addresses introduced don't match
      </p>
    </div>
<!--    <div style="margin-top: 1.5rem;" class="mb-4">
      <h5 class="fs-la">I am..</h5>
      <vSelect
        :value="status"
        class="w-100 select-status fs-md mb-2"
        label="I am.."
        :clearable="false"
        :options="statuses"
        :placeholder="`Select your current situation`"
        :multiple="false"
        @input="(e) => selectedChange('status', e)"
      >
      </vSelect>
      <p class="mb-0 fs-md" v-if="errors.status" style="color: #c64a45">* This field is required</p>
    </div>-->
    <div style="background-color:#01B65F;margin-top: 1.5rem;" class="label-pages d-flex p-2 px-2 align-items-center">
      <p style="padding: 2%;font-size: 14px" class="ms-1 mb-0 fs-xs">
        <strong>Good to know:</strong> <br>We send address updates based on
        your move dates so if you’re moving in the future
        we’ll hold off on processing your move until closer to
        the time 😊
      </p>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import { isValid } from "postcode";
import pcToWater from "@/data/postCodeToWaterSupplier";
import { navigateTo } from "../router/index";

import vSelect from "vue-select";
import { scrollToBottom } from "../utils/scrollToTop.js";
import { isMobile } from "../utils/isMobile";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    vSelect,
  },
  data() {
    return {
      options: [],
      options2: [],
      statuses: ["Moving home in the future","Moving home today","Already in my new home"],
      displayConfirmEmail: false,
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        confirmEmail: false,
        existsUnderDifferentName: false,
      },
    };
  },
  methods: {

    selectedChange(itemName, value) {
      // console.log("selectedChange", value);
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    regexEmail(email) {
      //Email
      const regexMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      return regexMail.test(email);
    },
    validateEmail() {
      if (this.email == "" || !this.regexEmail(this.email)) {
        this.errors.email = true;
      } else {
        this.errors.email = false;
      }
    },
    validateInputs() {
      this.validateEmail();
      this.errors.firstName = this.firstName.trim() == "" ? true : false;
      this.errors.lastName = this.lastName.trim() == "" ? true : false;

      //Confirm Email
      if (this.email !== this.confirmEmail || !this.regexEmail(this.confirmEmail)) {
        this.errors.confirmEmail = true;
      } else {
        this.errors.confirmEmail = false;
      }

    },

    updateValue(itemName, value, inputPostcodes) {
      // console.log("updateValue", value);
      if (inputPostcodes) {
        value = value.toUpperCase();
      }

      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    formatOptionSelected(option) {
      return option.label.length >= 47 && window.innerWidth <= 475
        ? option.label.slice(0, 47) + "..."
        : option.label;
    },
    dropdownShouldOpen(VueSelect) {
      return VueSelect.search.length > 5 && VueSelect.open;
    },
    isCanopyFree() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
    /*  fetch(
        `${this.baseURL}/user/utils/canopyFree?email=${this.email}`,
        requestOptions
      ).then((res) => {
        res.json().then((json) => {

          if (json ===false){
            return false
          }else{
            this.$store.commit("formStore/setField", ["referrer", 'cExtended']);
            this.$store.commit("formStore/setField", ["title", json.title]);
            this.$store.commit("formStore/setField", ["firstName", json.firstName]);
            this.$store.commit("formStore/setField", ["lastName", json.lastName]);
          }
        });
      });*/


    },
  },
  mounted() {
    this.$store.commit("setField", ["titleNavbar", "About your move"]);

    this.displayConfirmEmail = this.regexEmail(this.email);

    if (this.addressManually) {
      document.getElementById("inputAdressesManual").style.maxHeight = `100vh`;
    }
    const validatePage = async () => {
      this.validateInputs();
      await fetchExistsUnderDifferentName().then((exists) => {
        if (exists) {
          this.errors.existsUnderDifferentName = true;
        } else {
          this.errors.existsUnderDifferentName = false;
        }
      });
      if (!Object.values(this.errors).includes(true)) {
        // this.$router.push("/services");
        /*if (this.referrer==='cExtended'){
          navigateTo("/services");
        }else{*/
          navigateTo("/home2");
        //}
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };
    const fetchExistsUnderDifferentName = () => {
      return fetch(
        `${this.baseURL}/user/utils/existsUnderDifferentName?email=${this.email}&firstName=${this.firstName}&lastName=${this.lastName}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        return res.json();
      });
    };
    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        /* (this.referrer == "adv2" || this.referrer == "amber") && */ this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },

  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "email",
      "firstName",
      "lastName",
      "status",
      "confirmEmail",
      "referrer",
      "baseURL",
      "urlBucket",
      "moveToPostCode",
      "moveFromPostCode",
      "moveToAddress",
      "moveFromAddress",
      "hasPreviousAddress",
      "addressManually",
      "completeMoveToAddress",
      "completeMoveFromAddress",
    ]),
  },
  watch: {
    hasPreviousAddress(newVal, oldVal) {
      let className = "hide-address-from-input";

      if (newVal == false) {
        document.getElementsByClassName("container-pin2")[0].classList.add(className);
        document.querySelector(".search-2 .vs__dropdown-toggle").classList.add(className);
      }

      if (newVal == true) {
        document.getElementsByClassName("container-pin2")[0].classList.remove(className);
        document.querySelector(".search-2 .vs__dropdown-toggle").classList.remove(className);
      }
    },
    completeMoveToAddress: function (obj, oldVal) {
      // console.log("completeMoveToAddress", obj);
      //newCouncil - newWater
      let outcodeNew = obj.postcode.split(" ")[0];

      this.$store.commit("formStore/setField", ["newWater", pcToWater[outcodeNew]]);

      this.$store.commit("formStore/setField", [
        "newCouncil",
        obj.district ? obj.district : obj.county,
      ]);
    },
    completeMoveFromAddress: function (obj, oldVal) {
      // console.log("completeMoveFromAddress", obj);
      //oldWater - oldCouncil
      this.$store.commit("formStore/setField", ["oldWater", pcToWater[obj.postcode.split(" ")[0]]]);
      this.$store.commit("formStore/setField", [
        "oldCouncil",
        obj.district ? obj.district : obj.county,
      ]);
    },
    email(newVal, oldVal) {
      this.displayConfirmEmail = this.regexEmail(newVal);
      /*if (this.displayConfirmEmail){
        this.isCanopyFree();
      }*/
    },
  },
};
</script>
<style lang="scss">
.home {
  .select-status {
    .vs__selected {
      color: black;
    }
    .vs__dropdown-toggle {
      padding: 0.8rem !important;
      border-radius: 0.375rem;
      color: black;
    }

    .vs__dropdown-menu {
      color: black !important;
    }
  }
  input:disabled {
    background-color: white;
  }

  .v-select {
    .vs__search,
    .vs__search:focus {
      font-size: inherit;
      padding-left: 16px;
      /*padding: 0px !important;*/
    }

    .vs__actions {
      padding-right: 12px;
    }

    color: black;
    font-weight: 600;

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__selected {
      margin: 4px 0px 0;
      padding-left: 16px;
      font-weight: 400;
    }

    input::placeholder {
      color: #828282;
    }
  }

  .container-pins {
    padding: 7.5px;
  }

  .container-pin1 {
    background-color: white;
    border-radius: 0.4rem 0 0 0;

    .pin1 {
      height: 40px;
    }
  }

  .container-pin2 {
    background-color: #32343e;
    border-radius: 0 0 0 0.4rem;

    .pin2 {
      height: 40px;
    }
  }

  .hide-address-from-input {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  .search-1 {
    .vs__dropdown-toggle {
      border-radius: 0.4rem;
      /*border-radius: 0 0.4rem 0 0;*/
      z-index: 9999999 !important;
      padding-left: 0px !important;
    }

    .vs__open-indicator {
      fill: black !important;
    }
  }

  .search-2 {
    .v-select {
      color: black;
      font-weight: 600;

      .vs__dropdown-toggle {
        border-radius: 0 0 0.4rem 0;
        background-color: #32343e;
        padding-left: 0px !important;
      }

      .vs__search {
        color: white;
      }

      .vs__selected {
        color: white;
      }

      .vs__open-indicator {
        fill: white !important;
      }
    }
  }
}

.pin {
  width: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

#inputAdressesManual {
  max-height: 0;
  margin: 0 auto;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;

  &:before {
    border-radius: 50%;
  }
}
</style>
