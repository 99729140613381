import store from "../store/index.js";
import { router } from "./index.js";

let pagesVisited = [];

const deleteRoute = (currentRoute) => {
  pagesVisited = pagesVisited.filter((route) => route !== currentRoute);
  // console.log(pagesVisited)
};

const clearRoutes = () => (pagesVisited = []);

const setRoute = (state, currentRoute) => {
  store.commit("setField", ["step3", true]);

  const formC = Object.assign({ state });
  // console.log(pagesVisited)
  const {
    informDentist,
    informDVLA,
    informNHS,
    informCouncilTax,
    informInsurance,
    informOpticians,
    informPets,
    informPension,
    informGyms,
    informHomecare,
    informBreakdown,
    informBanks,
    informDWP,
    informLoyalty,
    informWaterSewerage,
    informEnergy,
    informMobile,
    informCharities,
  } = formC.state;

  //These are the routes for the flow of pages with the questions as the user selects some type of service
  const routes = [
    {
      route: "/councils",
      condition: informCouncilTax || informWaterSewerage || informEnergy,
    },
   // { route: "/dvla", condition: informDVLA },
    // { route: "/general-info-2", condition: informNHS || informDentist },
    { route: "/insurance", condition: informInsurance },
    { route: "/pets", condition: informPets },
    {
      route: "/general-info-3",
      condition:
        informPension || informCharities || informGyms || informHomecare || informBreakdown,
    },
    { route: "/dwp", condition: informDWP },
    { route: "/banks", condition: informBanks },
    { route: "/mobile-providers", condition: informMobile },
    { route: "/loyalties", condition: informLoyalty },
    { route: "/opticians", condition: informOpticians },
  ];

  //let routeToNavigate = "/about-you";
  let routeToNavigate = "/review";

  for (const { route, condition } of routes) {
    // console.log(router.currentRoute.path)

    if (!pagesVisited.includes(route) && condition) {
      pagesVisited.push(route);
      routeToNavigate = router["currentRoute"].path !== route && route;

      break;
    }
  }

  pagesVisited = [...new Set(pagesVisited)];

  // console.log(routeToNavigate)

  return routeToNavigate;
};

export { setRoute, deleteRoute, clearRoutes };
