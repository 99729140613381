import { uuid } from "uuidv4";
import { router } from "../router/index";

export default {
  namespaced: true,
  state: {
    referrer: process.env.VUE_APP_ENVIRONMNENT != "production" ? "DEVELOPMENT" : "SlothMove",
    article: "",
    ownedLead: false,
    userId: uuid(),
    email: "",
    confirmEmail: "",
    title: "",
    firstName: "",
    status: "",
    lastName: "",
    contactNumber: "",
    showInputContactNumber: true,
    premium: false,
    isPlus: false,
    freeUpdate: false,
    priceOffered: 35,
    slothMoveSelection: "normal",
    cardOptionSelected: "",
    taylorRoseCode: undefined,
    hasPreviousAddress: true,
    newResidentialStatus: "",
    moveToAddress: "",
    moveFromAddress: "",
    addressManually: false,
    moveFromPostCode: "",
    moveToPostCode: "",
    completeMoveFromAddress: undefined,
    completeMoveToAddress: undefined,
    moveInDate: "",
    moveOutDate: "",
    showInputMoveInDate: true,
    iKnowMyMoveInDate: true,
    iWantAllServices: false,
    moveOutFlag: false,
    dayMD: "",
    monthMD: "",
    yearMD: "",
    speakToHuman: false,
    completeItOnline: false,
    termsChecked: false,
    baseURL: "/api",
    urlTest: "http://ec2-18-116-203-41.us-east-2.compute.amazonaws.com:8090",
    urlBucket: "https://drmig4hxr8n3m.cloudfront.net",
    //https://bucket-image-container.s3.amazonaws.com
    toggleAutocompleteInputs: false,
    // Page Services
    //price-transparency
    priceBase: 20,
    discountedPrice: 15,
    //price-transparency
    informLoyalty: false,
    informStudentFinance: false,
    informNHS: false,
    informDVLA: false,
    informBanks: false,
    informGyms: false,
    informDWP: false,
    informSpam: false,
    informMobile: false,
    informDentist: false,
    informPension: false,
    informTVLicense: false,
    informElectoralRoll: false,
    informHMRC: false,
    informBonds: false,
    informCharities: false,
    informBreakdown: false,
    informHomecare: false,
    informPets: false,
    informOpticians: false,
    informChildBenefit: null,
    informAttendanceAllowance: null,
    informDisabilityLivingAllowance: null,
    informPensionCredits: null,
    informPersonalIndependencePayment: null,
    informStatePension: null,
    insuraceTypeSelected: "",
    insurancesSelection: [],
    pets: [],
    pensionSelection: [],
    mobileSelection: [],
    selectedBanks: [],
    selectedGyms: [],
    selectedCharities: [],
    selectedLoyalties: [],
    selectedHomecare: [],
    selectedBreakdown: [],
    selectedOpticians: [],
    membersList: [],
    selectedNationalities: [],
    suggestedCompanies: [],
    selectedOpenRegister: "",
    selectedPostalVote: "",
    selectedSendingMethod: "",
    selectedNINO: "",
    selectedDBSSales: null,
    selectedTPS: null,
    dentistName: undefined,
    dentistManual: false,
    gpName: undefined,
    gpsManual: false,
    informLottery: false,
    informEnergy: false,
    informOctopusEnergy: false,
    dateOfBirth: "",
    dayDOB: "",
    monthDOB: "",
    yearDOB: "",
    //Energy - Gas
    selectedEnergySupplier: [],
    selectedGasSupplier: [],
    // PAGE Council
    informOldCouncil: false,
    informOldWater: false,
    informNewWater: false,
    informNewCouncil: false,
    oldCouncil: "",
    newCouncil: "",
    oldWater: "",
    newWater: "",
    singleOccupant: false,
    //PageYourHome
    informCouncilTax: false,
    informWaterSewerage: false,
    informBroadbandMedia: false,
    informInsurance: false,
    responsibleForTheBills: true,
    //DVLA
    dvlaNeed: "",
    updateDrivinglicense: false,
    updateV5C: false,
    vehiclesRegistered: [{ carRegistration: "", info: {}, img: "" }],
    // carRegistration:"",
    //DVLA

    //Page General Info 2
    isSearchingForGP: "",
    isSearchingForDentist: "",
    //Page Review
    campaignChecked: false, //<-for essentials
    timeSaved: 0,
    moneySaved: 0,
    warningSlothPlus: false,
  },

  mutations: {
    setField(state, [fieldName, payload]) {
      state[fieldName] = payload;
    },
    restoreForm(state, objFormStore) {
      for (const [key, value] of Object.entries(objFormStore)) {
        // console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    log(state, [fieldName, payload]) {
      //fetch(`http://192.168.1.29:8090/logger/write`, {
      fetch(`${state.baseURL}/logger/write`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          content: fieldName + " " + payload,
        }),
      })
        .then((res) => res.json())
        .then((data) => {});
    },
  },
  actions: {
    toggleValue({ state, commit }, [name, value]) {
      if (value !== undefined) {
        commit("setField", [name, value]);
      } else {
        commit("setField", [name, !state[name]]);
      }
    },
    savePartialData({ state, rootState, getters }) {
      let partial = {};
      partial.currentData = getters["formattedFormResults"];
      partial.visitedRoutes = rootState["visitedRoutes"];

      const pageNumbers = {
        PagePartner: -2,
        Home: -1,
        PageServices: 0,
      };

      partial.currentData["pageNumber"] = pageNumbers[router.currentRoute.name];

      // console.log({ partial }.partial.currentData.moveDetails)
      // console.log(JSON.stringify({ partial }.partial.currentData.moveDetails))

      //fetch(`http://127.0.0.1:8090/partials/add`, {
      fetch(`${state.baseURL}/partials/add`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ partial }),
      }).catch((error) => {
        // this.$store.commit('formStore/log', ['Partials', state.email])
      });
    },
    homeSetupPayload({ state, rootState, getters }) {
      let partial = {};
      partial.currentData = getters["formattedFormResults"];
      partial.visitedRoutes = rootState["visitedRoutes"];

      const pageNumbers = {
        PagePartner: -2,
        Home: -1,
        PageServices: 0,
      };

      partial.currentData["pageNumber"] = pageNumbers[router.currentRoute.name];

      fetch(`${state.baseURL}/partials/addWithHomeSetup`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ partial }),
      }).catch((error) => {});
    },
  },
  getters: {
    formattedFormResults: (state) => {
      const {
        referrer,
        article,
        priceOffered,
        premium,
        isPlus,
        email,
        title,
        firstName,
        lastName,
        contactNumber,
        moveToAddress,
        moveFromAddress,
        completeMoveFromAddress,
        completeMoveToAddress,
        moveFromPostCode,
        moveToPostCode,
        moveInDate,
        moveOutDate,
        speakToHuman,
        completeItOnline,
        newResidentialStatus,
        //Council
        informOldWater,
        informNewWater,
        informOldCouncil,
        informNewCouncil,
        oldCouncil,
        oldWater,
        newCouncil,
        newWater,
        singleOccupant,
        selectedEnergySupplier,
        selectedGasSupplier,
        // Services
        informLoyalty,
        informStudentFinance,
        informNHS,
        informDVLA,
        informBanks,
        informGyms,
        informDWP,
        informSpam,
        informMobile,
        informDentist,
        informPension,
        informTVLicense,
        informElectoralRoll,
        informHMRC,
        informBonds,
        informCharities,
        informLottery,
        informEnergy,
        informPets,
        informHomecare,
        informBreakdown,
        informInsurance,
        informChildBenefit,
        informAttendanceAllowance,
        informDisabilityLivingAllowance,
        informPensionCredits,
        informPersonalIndependencePayment,
        informStatePension,
        informOpticians,
        selectedNINO,
        pensionSelection,
        mobileSelection,
        selectedBanks,
        selectedGyms,
        selectedCharities,
        selectedLoyalties,
        selectedHomecare,
        selectedBreakdown,
        selectedOpticians,
        insurancesSelection,
        suggestedCompanies,
        pets,
        dentistName,
        gpName,
        isSearchingForGP,
        isSearchingForDentist,
        selectedOpenRegister,
        //DVLA
        dvlaNeed,
        updateDrivinglicense,
        updateV5C,
        vehiclesRegistered,
        //PageReview
        campaignChecked,
      } = state;

      return Object.assign({
        submissionDate: new Date(Date.now()).toGMTString(),
        referrer,
        article,
        priceOffered,
        premium,
        suggestedCompanies: suggestedCompanies.filter((c) => c !== ""),
        isPlus,
        userDetails: {
          email,
          title,
          firstName,
          lastName,
          contactNumber,
        },
        moveDetails: {
          newResidentialStatus,
          moveToAddress,
          moveFromAddress,
          completeMoveFromAddress,
          completeMoveToAddress,
          moveFromPostCode,
          moveToPostCode,
          moveInDate,
          moveOutDate,
        },
        councils: {
          informOldCouncil,
          informOldWater,
          informNewWater,
          informNewCouncil,
          oldCouncil,
          oldWater,
          newCouncil,
          newWater,
          singleOccupant,
        },
        essentials: {
          campaignChecked,
        },
        services: {
          speakToHuman,
          completeItOnline,
          dentistName,
          gpName,
          isSearchingForGP,
          isSearchingForDentist,
          selectedOpenRegister,
          informLoyalty,
          informStudentFinance,
          informNHS,
          informDVLA,
          informBanks,
          informGyms,
          informDWP,
          informSpam,
          informMobile,
          informDentist,
          informPension,
          informTVLicense,
          informElectoralRoll,
          informHMRC,
          informBonds,
          informCharities,
          informLottery,
          informEnergy,
          selectedNINO,
          informPets,
          informHomecare,
          informBreakdown,
          informInsurance,
          informChildBenefit,
          informAttendanceAllowance,
          informDisabilityLivingAllowance,
          informPensionCredits,
          informPersonalIndependencePayment,
          informStatePension,
          informOpticians,
          pensionSelection: pensionSelection.map((item) => item.name),
          mobileSelection: mobileSelection.map((item) => item.name),
          selectedBanks: selectedBanks.map((item) => item.name),
          selectedGyms: selectedGyms.map((item) => item.name),
          selectedCharities: selectedCharities.map((item) => item.name),
          selectedLoyalties: selectedLoyalties.map((item) => item.name),
          selectedEnergySupplier: selectedEnergySupplier[0]?.name,
          selectedGasSupplier: selectedGasSupplier[0]?.name,
          selectedHomecare: selectedHomecare.map((item) => item.name),
          selectedBreakdown: selectedBreakdown.map((item) => item.name),
          insurancesSelection: insurancesSelection.map((item) => item.name),
          selectedOpticians: selectedOpticians.map((item) => item.name),
          pets,
        },
        // family:{
        //   membersList,
        // },
        dvlaQuestions: {
          dvlaNeed,
          updateDrivinglicense,
          updateV5C,
          vehiclesRegistered,
        },
      });
    },
    state: (state) => {
      return Object.assign({ ...state });
    },
  },
};
